import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateRange, MomentService } from '@fc-core';
import { Observable } from 'rxjs';
import { Moment } from 'moment';
import { ConfirmDialogComponent } from 'src/app/locations/components';

@Component({
  selector: 'fc-operator-schedule-form',
  templateUrl: './operator-schedule-form.component.html',
  styleUrls: ['./operator-schedule-form.component.scss'],
})
export class OperatorScheduleFormComponent<T extends { id: number }>
  implements OnInit
{
  dateRange: DateRange;
  activeTab = 'range';
  day: UntypedFormControl = new UntypedFormControl(null);
  selectedItemControl: FormControl = new FormControl<number>(-1);

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      event: any;
      itemsList$: Observable<T[]>;
      loading$: Observable<boolean>;
      searchKey: string;
    },
    private momentService: MomentService,
  ) {}

  get formTitle() {
    const typeTitle = this.formType === 'vehicle' ? 'Vehicle' : 'Driver';
    return this.data?.event?.operator || this.data?.event?.vehicle
      ? `Edit Assigned ${typeTitle}`
      : `Assign ${typeTitle}`;
  }

  ngOnInit(): void {
    if (this.data?.event?.operator) {
      this.selectedItemControl.patchValue(this.data.event.operator.id);
    } else if (this.data?.event?.vehicle) {
      this.selectedItemControl.patchValue(this.data.event.vehicle.id);
    }
    if (!this.data.event) {
      this.fillDates();
      return;
    }
    this.day.setValue(this.data.event.duration.lower);
    this.dateRange = {
      start: this.momentService.moment(this.data.event.duration.lower),
      end: this.momentService.moment(this.data.event.duration.upper),
    };
    if (!this.data.event.duration.upper) delete this.dateRange.end;
    this.setTab();
  }

  fillDates() {
    this.dateRange = {
      start: this.momentService.moment(),
      end: this.momentService.moment(),
    };
    this.day.setValue(this.momentService.moment());
  }

  setTab() {
    if (
      this.data.event.duration.continuous === true ||
      (!this.data.event.duration.upper && !this.data.event.duration.continuous)
    ) {
      this.activeTab = 'ongoing';
      return;
    }
    this.momentService
      .moment(this.data.event.duration.upper)
      .diff(this.momentService.moment(this.data.event.duration.lower), 'days')
      ? (this.activeTab = 'range')
      : (this.activeTab = 'oneDay');
  }

  switchTab(tab) {
    this.activeTab = tab;
    const startDate = this.data.event
      ? this.momentService.moment(this.data.event.duration.lower)
      : this.momentService.moment();
    const endDate = this.data.event
      ? this.momentService.moment(this.data.event.duration.upper)
      : this.momentService.moment().endOf('day');
    switch (tab) {
      case 'range':
        this.dateRange = {
          start: startDate.startOf('day'),
          end: endDate,
        };
        break;
      case 'oneDay':
        this.dateRange = {
          start: startDate.startOf('day'),
          end: startDate.endOf('day'),
        };
        break;
      case 'ongoing':
        this.dateRange = {
          start: startDate.startOf('day'),
          end: null,
        };
        break;
      default:
        break;
    }
  }

  setOneDay(date: Moment) {
    this.dateRange = {
      start: date,
      end: date,
    };
  }

  setOngoing(date: Moment) {
    this.dateRange = {
      start: date,
      end: null,
    };
  }

  onSubmit() {
    const key = this.data.searchKey === 'fullName' ? 'operator' : 'vehicle';
    const payload = {
      ...this.data.event,
      [key]: this.selectedItemControl.value,
      duration: {
        lower: this.dateRange.start?.clone().startOf('day').toISOString(),
        upper: this.dateRange.end?.clone().endOf('day').toISOString(),
      },
    };
    if (!this.dateRange.end) delete payload.upper;
    this.dialogRef.close(payload);
  }

  get formType(): string {
    return this.data.searchKey === 'fullName' ? 'employee' : 'vehicle';
  }
}
