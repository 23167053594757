<div class="calendar">
  <div class="calendar-day-names">
    <div class="calendar-day-name" *ngFor="let name of namesOfDays">
      {{ name }}
    </div>
  </div>
  <div cdkDropListGroup class="calendar-dates">
    <div class="calendar-row" *ngFor="let row of monthRow">
      <div
        cdkDropList
        [cdkDropListData]="day"
        cdkDropListSortingDisabled
        class="calendar-date"
        *ngFor="let day of row; trackBy: trackByFn"
        [ngClass]="{
          'resizing-above': isEventResizingAbove(day.date),
          'not-current-month': day.notThisMonth,
          today: day.today
        }"
      >
        <div class="calendar-number">
          <span class="date">{{ day.date.date() }}</span>
        </div>
        <div
          class="events"
          [ngStyle]="{ 'grid-template-rows': getMaxRows() }"
          (click)="dayClicked(day, popoverTrigger, popoverAttachment)"
          #popoverTrigger
        >
          <div
            (mouseenter)="mouseEnterEvent(event)"
            (mouseleave)="mouseLeaveEvent()"
            *ngFor="let event of getDayEvents(day); trackBy: trackByFn"
            [class.disabled]="event.isDisabled"
            [ngClass]="{
              'row-start-event': firstInRow(row, day.date, event),
              'start-event': isFirst(day.date, event),
              'end-event': isLast(day.date, event),
              hovered: isHovered(event)
            }"
            [ngStyle]="{ 'grid-row-start': event.order + 1 }"
            [style.width.px]="getEventWidth(event, row, day.date)"
            class="event-container"
          >
            <div
              *ngIf="isFirst(day.date, event)"
              cdkDrag
              (cdkDragEntered)="setResizingEvent($event, event, 'start')"
              (cdkDragDropped)="resizeDropped($event, event, 'start')"
              class="resize resize-start"
            >
              <div *cdkDragPreview></div>
              <div *cdkDragPlaceholder></div>
            </div>
            <div
              class="event"
              (click)="$event.stopPropagation()"
              #eventMenuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="eventMenu"
            >
              <div
                *ngIf="
                  firstInRow(row, day.date, event) || isFirst(day.date, event)
                "
                class="event-title"
              >
                <div class="event-content">
                  <ng-container
                    *ngTemplateOutlet="
                      itemTemplate;
                      context: { $implicit: event }
                    "
                  ></ng-container>
                </div>
              </div>
              <mat-menu #eventMenu="matMenu">
                <button
                  mat-menu-item
                  (mousedown)="onMouseClicked(event, eventMenuTrigger)"
                >
                  <fc-icon icon="icon-create"></fc-icon>
                  Edit
                </button>
                <button
                  mat-menu-item
                  (mousedown)="removeEvent(event, eventMenuTrigger)"
                >
                  <fc-icon icon="icon-delete_outline"></fc-icon>
                  Delete
                </button>
              </mat-menu>
            </div>

            <div
              *ngIf="isLast(day.date, event)"
              cdkDrag
              (cdkDragEntered)="setResizingEvent($event, event, 'end')"
              (cdkDragDropped)="resizeDropped($event, event, 'end')"
              class="resize resize-end"
            >
              <div *cdkDragPreview></div>
              <div *cdkDragPlaceholder></div>
            </div>
          </div>
          <div
            [ngStyle]="{ 'grid-row-start': getEventMaxOrder(day) }"
            #popoverAttachment
            class="placeholder event"
          >
            Select {{ itemType }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
