<div class="header">
  <h2 mat-dialog-title>
    {{ formTitle }}
  </h2>
  <div class="close">
    <fc-icon-button [mat-dialog-close]="null">
      <fc-icon icon="icon-clear"></fc-icon>
    </fc-icon-button>
  </div>
</div>
<mat-dialog-content class="edit-user-vehicle">
  <form>
    <mat-button-toggle-group
      class="navigation-toggle"
      (change)="switchTab($event.value)"
      [value]="activeTab"
    >
      <mat-button-toggle value="range"> Range </mat-button-toggle>
      <mat-button-toggle value="oneDay"> One Day </mat-button-toggle>
      <mat-button-toggle value="ongoing"> Ongoing </mat-button-toggle>
    </mat-button-toggle-group>
    <div class="vehicle-select">
      <fc-single-item-dropdown
        [additionalTemplate]="vehiclePhoto"
        [formControl]="selectedItemControl"
        [itemList]="data.itemsList$ | async"
        [searchKey]="data.searchKey"
        [title]="
          formTitle === 'Assign Vehicle' ? 'Select Vehicle' : 'Select Driver'
        "
      ></fc-single-item-dropdown>
    </div>
    <fc-material-range-picker-input
      *ngIf="activeTab === 'range'"
      class="fc-date-picker"
      [(ngModel)]="dateRange"
      [ngModelOptions]="{ standalone: true }"
      [dateInputFormat]="'MMM. DD YYYY'"
    ></fc-material-range-picker-input>
    <fc-inline-date-picker
      *ngIf="activeTab === 'oneDay'"
      [date]="day"
      (dateChanged)="setOneDay($event)"
      [maxDate]="null"
    >
    </fc-inline-date-picker>
    <fc-inline-date-picker
      *ngIf="activeTab === 'ongoing'"
      [date]="day"
      (dateChanged)="setOngoing($event)"
      [maxDate]="null"
    >
    </fc-inline-date-picker>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="buttons">
  <button
    [mat-dialog-close]="null"
    class="action__button action__button_cancel"
    mat-button
    type="button"
  >
    Cancel
  </button>

  <fc-loader-button
    (buttonClick)="onSubmit()"
    [options]="{
      buttonColor: 'primary',
      spinnerColor: 'accent',
      raised: true,
      text: selectedItemControl.value ? 'save' : 'assign',
      spinnerSize: 20,
      active: data.loading$ | async,
      disabled: selectedItemControl.value < 0,
      mode: 'indeterminate',
    }"
  ></fc-loader-button>
</mat-dialog-actions>
<ng-template #vehiclePhoto let-option>
  <div class="dropdown-image {{ formType }}">
    <ng-container *ngIf="formType === 'vehicle'">
      <fc-image-loader
        [notClickable]="true"
        [currentIndex]="0"
        [thumbWidth]="20"
        [thumbHeight]="16"
        [placeholderSize]="'11px'"
        [photoModels]="[option?.photo]"
      ></fc-image-loader>
    </ng-container>
    <ng-container *ngIf="formType === 'employee'">
      <fc-user-photo
        *ngIf="option?.avatar; else placeholder"
        [employeePhoto]="option?.avatar"
        [width]="20"
        [height]="20"
      ></fc-user-photo>
      <ng-template #placeholder>
        <fc-avatar-placeholder
          [color]="option?.color"
          [fontSize]="10"
          [size]="20"
          [text]="option?.firstName[0] + option?.lastName[0]"
        ></fc-avatar-placeholder>
      </ng-template>
    </ng-container>
  </div>
</ng-template>
