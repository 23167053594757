import { NgModule } from '@angular/core';

import { CalendarComponent } from './calendar.component';
import { ControlsComponent } from './controls/controls.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CommonModule } from '@angular/common';
import { DateContentComponent } from './date-content/date-content.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { PopoverService } from '@fc-core';
import { SearchListComponent } from './search-list/search-list.component';
import { OperatorScheduleFormComponent } from 'src/app/employees/components/dialogs/operator-schedule-form/operator-schedule-form.component';
import { EmptyStateModule } from '../empty-state/empty-state.module';
import { ImageLoaderModule } from '../image-loader/image-loader.module';
import { MaterialsRangePickerModule } from '../material-range-picker/material-range-picker.module';
import { SearchDropdownModule } from '../search-dropdown/search-dropdown.module';
import { CustomInlineDatePickerModule } from '../vehicle-date-picker/custom-inline-date-picker.module';
import { ConfirmDialogModule } from '../confirm-dialog/confirm-dialog.module';
import { StoreModule } from '@ngrx/store';
import {
  scheduleCalendarKey,
  scheduleCalendarReducer,
} from '@fc-shared/ui/calendar/store/schedule-calendar.reducer';
import { AvatarPlaceholderModule } from '@fc-shared/ui/avatar-placeholder/avatar-placeholder.module';
import { TableSearchHeaderModule } from '@fc-shared/ui/tables/table-search-header/table-search-header.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { LoaderButtonModule } from '@fc-shared/ui/loader-button/loader-button.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MomentFormatPipeModule } from '@fc-shared/pipes/moment-format-pipe/moment-format-pipe.module';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { IconButtonComponent } from '@fc-shared/ui/buttons/icon-button.component';
import { TextButtonComponent } from '@fc-shared/ui/buttons/text-button.component';

@NgModule({
  imports: [
    CommonModule,
    MatDatepickerModule,
    DragDropModule,
    MatDatepickerModule,
    OverlayModule,
    EmptyStateModule,
    SearchDropdownModule,
    ImageLoaderModule,
    CustomInlineDatePickerModule,
    MaterialsRangePickerModule,
    ConfirmDialogModule,
    StoreModule.forFeature(scheduleCalendarKey, scheduleCalendarReducer),
    AvatarPlaceholderModule,
    TableSearchHeaderModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogModule,
    LoaderButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MomentFormatPipeModule,
    IconComponent,
    IconButtonComponent,
    TextButtonComponent,
  ],
  exports: [CalendarComponent, OperatorScheduleFormComponent],
  declarations: [
    CalendarComponent,
    ControlsComponent,
    DateContentComponent,
    SearchListComponent,
    OperatorScheduleFormComponent,
  ],
  providers: [PopoverService],
})
export class CalendarModule {}
